import React from 'react';
  import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Pagination,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Box,
    Divider
  } from '@mui/material';
  import { styled } from '@mui/material/styles';
  import  { tableCellClasses } from '@mui/material/TableCell';
import { useEffect,useState} from 'react';
import { toast } from 'react-toastify';
import { GetUser_ForDashboard,GetSingleUser,EditUser } from '../../Services/Authservices';
import moment from 'moment/moment';
import SpinnerModal from '../../Spinner/SpinnerModal';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';
import './UserManagement.css';
import SignUp from '../SignUp';

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#2c6bd7',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      backgroundColor: '#ffffff',
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nStyledTableCell-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child StyledTableCell, &:last-child StyledTableCell': {
      border: 0,
    },
  }));

  const Transition = React.forwardRef(function Transition(props, ref) {
    const transitionDuration = 500; // milliseconds
    return <Slide direction="down" ref={ref} {...props} timeout={transitionDuration} />;
  });
  
  const UserLists = () =>{
    const[userDetails,SetuserDetails] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [UserID, setUserd] = useState();

    const openModal = () => {
        setModalIsOpen(true);
      };
      const closeModal = () => {
        setModalIsOpen(false);
      };

    const GetUser  = async() =>{
        try{
          setIsLoading(true);
          const LabID = sessionStorage.getItem('LabID');
          const result  = await GetUser_ForDashboard(LabID)
          SetuserDetails(result)
        }catch(error){
         toast.error("Unabled to get user data",error)
        }finally{
            setIsLoading(false);
        }
       
      }

      useEffect(() => {
        GetUser();
      }, []);

     //-------------------Pagination

const itemsPerPage = 10; // Number of items per page
const [currentPage, setCurrentPage] = useState(1); // Pages start from 1

// Calculate the range of items to display on the current page
const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const limitedData = userDetails.slice(indexOfFirstItem, indexOfLastItem);
// Handle page change
const handlePageChange = (event, newPage) => {
  setCurrentPage(newPage);
};
//---------------------END---------
//----------------Edit user---------
const[formData,setformData] = useState({
  FirstName:'',
  LastName:'',
  Email :'',
  PhoneNumber:'',
  Password:'',
  user_Role:'',
  LaboratoryID : sessionStorage.getItem('LabID'),
});
const onchange = (e) =>{
  const {name,value} = e.target;
  setformData({
      ...formData,
      [name]:value,
  }) 
}
const Role = [
  {
    value: 'Admin',
    label: 'Admin',
  },
  {
    value: 'User',
    label: 'User',
  },
 
];

//-----------GET single user-----------

const getsingleuser = async (userID)  =>{
  const LabID =  sessionStorage.getItem('LabID');
  const repo = await GetSingleUser(LabID,userID);
  console.log("repo",repo)
  setformData({
    FirstName:repo.firstName || "",
  LastName:repo.lastName || '',
  Email : repo.email || '',
  PhoneNumber: repo.phoneNumber|| '',
  Password:repo.password ||'',
  user_Role:repo.user_Role || '',
  LaboratoryID : sessionStorage.getItem('LabID'),
  });
  setModalIsOpen(true);
}

  const onsubmit = async(e) =>{
    e.preventDefault();
    setIsLoading(true)
    try{
        const response = await  EditUser(formData)
        if(response){
            toast.success("Data saved successfully");
        }
    }
    catch(error){
        console.log(error)
    }
    finally{
        setIsLoading(false)
    }
   } 

    return(<>
          {isLoading && <SpinnerModal isLoading={true} />}
         <div  className="container-fluid Container2">
          <div className='common-box-shadow common-bg-white common-br-3px Common-min-height'>
            <div className='UM-label'>
            <h5 className='common-FontWeight common-FontFamily'>User Management</h5>
            </div>

              <div>
              <SignUp userId={UserID}/>
              </div>
            {/* <table  className='table table-borderless'>
                        <thead>
                            <tr className='US-tr'>
                                <td className='DTC1'>User Name</td>
                                <td className='DTC1'>Email</td>
                                <td className='DTC1'>Role</td>
                                <td className='DTC1'>
                                </td>
                            </tr>
                        </thead>
                        <tbody>  
                              {userDetails.map((data, index) => (
                                <tr  key={index}>
                                <td className='DTC'>{data.firstName} {data.lastName}</td>
                                <td className='DTC'>{data.email}</td>
                                <td className='DTC'>{data.user_Role}</td>
                                <td className='DTC'>
                                <button type='button'className='Delete-Edit-Button' onClick={openModal}><EditRoundedIcon sx={{fontSize:'18px'}} />change</button>
                                </td> 
                                </tr>
                                ))}
                        </tbody>
            </table> */}
            
            <TableContainer className='PatientListTable'>
                <Table  stickyHeader aria-label="sticky table" >
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell className='PTC1'>User Name</StyledTableCell>
                            <StyledTableCell className='PTC1'>Email</StyledTableCell>
                            <StyledTableCell className='PTC1'>Password</StyledTableCell>
                            <StyledTableCell className='PTC1'> Role</StyledTableCell>
                            <StyledTableCell className='Patient-List-Action'>Action's</StyledTableCell>

                        </StyledTableRow>
                    </TableHead>
                    {limitedData.length > 0 ?
                    <TableBody>  
                           {limitedData.map((data, index) => (
                            <StyledTableRow  key={index}>
                             
                             <StyledTableCell className='PTC'>{data.firstName} {data.lastName}</StyledTableCell>
                             <StyledTableCell className='PTC'>{data.email}</StyledTableCell>
                             <StyledTableCell className='PTC'>{data.password}</StyledTableCell>

                             <StyledTableCell className='PTC'>{data.user_Role}</StyledTableCell>
                             <StyledTableCell className='PTC'>
                                <button type='button'className='Delete-Edit-Button' onClick={() =>getsingleuser(data.userID)}><EditRoundedIcon sx={{fontSize:'18px'}} />Edit User</button>
                             </StyledTableCell>
                            </StyledTableRow>
                            ))}
                    </TableBody>
                    :
                    <TableBody className='row' style={{textAlign:'center'}}>
                       <p style={{color:'gray'}}> No Record Found</p>
                    </TableBody>}
                </Table>
                <hr></hr>
                <Stack>
                  <Pagination
                      variant="outlined"
                      shape="rounded"
                      count={Math.ceil(userDetails.length / itemsPerPage)} // Total pages
                      page={currentPage}
                      onChange={handlePageChange} // Correct prop for handling page changes
                    />
                  </Stack>
                
            </TableContainer>
          </div>
          

         </div>
               <Dialog open={modalIsOpen} keepMounted onClose={closeModal}  TransitionComponent={Transition}>
                    <DialogTitle sx={{fontWeight:600,backgroundColor:'#2c6bd7',color:'white'}}>{"Edit User"}</DialogTitle>
                    <Divider></Divider>
                    <DialogContent>
                    <div className='container-fluid pt-1'>
       <form onSubmit={onsubmit}>
           <div className='row pt-1'>
                <div  className='col-4 mt-2'>
                   <label className='common-Labels'>First Name <span className='common-Asterisk'>*</span></label>
                          <input
                              className="form-control common-Input"
                              label='First Name'
                              id='FirstName'
                              name='FirstName'
                              value={formData.FirstName}
                              required
                              onChange={onchange} />
                  </div>
                  
                <div  className='col-4 mt-2'>
                       <label className='common-Labels'>Last tName <span className='common-Asterisk'>*</span></label>
                          <input
                              className="form-control common-Input"
                              label='Last tName'
                              id='LastName'
                              name='LastName'
                              value={formData.LastName}
                              required
                              onChange={onchange}/>
                  </div>
                
                <div  className='col-4 mt-2'>
                <label className='common-Labels'>Phone Number <span className='common-Asterisk'>*</span></label>
                          <input
                              className="form-control common-Input"
                               label='PhoneNumber'
                              id='PhoneNumber'
                              name="PhoneNumber"
                              value={formData.PhoneNumber}
                              required
                              onChange={onchange} />
                </div>
                <div  className='col-4 mt-2'>
                   <label className='common-Labels'>Email<span className='common-Asterisk'>*</span></label>
                          <input
                              className="form-control common-Input"
                               label='Email'
                              id='Email'
                              name='Email'
                              value={formData.Email}
                              required
                              onChange={onchange} />
                </div>
                <div  className='col-4 mt-2'>
                    <label className='common-Labels'>Password <span className='common-Asterisk'>*</span></label>
                          <input
                              className="form-control common-Input"
                              label='Password'
                              id='Password'
                              name='Password'
                              value={formData.Password}
                              onChange={onchange} />
                  </div>
                <div  className='col-4 mt-2'>
                     <label className='common-Labels'>User Role <span className='common-Asterisk'>*</span></label>
                      <select className="form-select  common-Input"
                            label='User Role'
                            id='user_Role'
                            name='user_Role'
                            required
                            onChange={onchange}
                            value={formData.user_Role}
                            >  
                            {Role.map((option) => (
                            <option key={option.value} value={option.value}>
                            {option.label}
                            </option>
                            ))}
                        </select>
                </div>
            </div>
               <div className='row pt-4 mb-2'>
                 <div  className='col-3'>
                     <button  type='submit' class=" common-button">Save</button>
                 </div>
               </div>
       </form>
   </div>
                    </DialogContent>
                </Dialog>
      </>)
  }

  export default   UserLists