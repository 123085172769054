// components/ImageUpload.js

import React, { useEffect, useState } from 'react';
import { AddLogoImage,GetLogoImage,AddDrInfo,ADDTEMPLATESETTING,GetDocotrInfo,DeleteDrInfo} from '../Services/Template';
import SpinnerModal from '../Spinner/SpinnerModal';
import { toast } from 'react-toastify';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Slide from '@mui/material/Slide';
import '../UI/TemplateModal.css'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  tableCellClasses,
  Divider, 
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#2c6bd7',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  const transitionDuration = 500; // milliseconds
  return <Slide direction="down" ref={ref} {...props} timeout={transitionDuration} />;
});

const TemplateSetting = () => {
  const [isLoading, setIsLoading] = useState(false); 
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [image,seImage] = useState(null);
  const [TEMPValue, setTEMPValue] = useState('');
  const [DRINFOData,SetDRData] = useState([]);
  
  const[isDelete,SetisDelete] = useState(false);
  const [DrID,setDRID] = useState([]);
  const [isDeleteSuccess,SetIsDeleteSuccess] = useState(false);
  const [boxBorderColor, setBoxBorderColor] = useState('lightgray'); 

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewImage(reader.result);
          const img = new Image();
           img.src = reader.result;
          img.onload = () => {
            const imgWidth = img.width;
            if (imgWidth > 650) {
              setBoxBorderColor('#56ea56');
            } else {
              setBoxBorderColor('red');
            }
            setPreviewImage(reader.result);
          };
        };
        reader.readAsDataURL(file);
    };


    const handleChange = (event) => {
      setTEMPValue(event.target.value);
    };

    const handleUpload = async (e) => {
      e.preventDefault();
      setIsLoading(true)
        try {
            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('LabID', sessionStorage.getItem('LabID'));
            formData.append('TEMP',TEMPValue)
            const response = await AddLogoImage(formData);
            if(response){
              toast.success("Template Selected")
            }
           } catch (error) {
            toast.error('Error uploading image:', error);
           }
           finally{
           setIsLoading(false)
           }
       };
//-----------Get image --------------
    const getimage = async() =>{
      try{
        setIsLoading(true);
        const LabID = sessionStorage.getItem('LabID')
        const result  = await GetLogoImage(LabID)
        seImage(result)
        setTEMPValue(result?.[0]?.selectedTemplate) 
      }catch(error){
        toast.error('Error Load data:', error);
      }finally{
        setIsLoading(false)
      }
    }
//------------------END------------

//-----------Use Effect--------
useEffect(() =>{
  getimage();
},[])

//-----------------End--------
//---------- Doctor information -----------
const [Drdata,setDrdata] = useState({
  drname:'',
  designation:'',
  description:'',
  address:'',
  LaboratoryID: sessionStorage.getItem('LabID')
})

const onChange = (e) =>{
  const {name,value} = e.target;
    setDrdata({
      ...Drdata,
      [name]:value
    })

}

const Submit = (e) =>{
  e.preventDefault();
  setIsLoading(true)
  try{
    if(Drdata.drname == '' || Drdata.designation == '' || Drdata.description == '' ){
      toast.warning('Please add doctor necessary information ')
    }else{
      const response = AddDrInfo(Drdata);
      if(response != null ){
       toast.success('Doctore info saved successfully')
       SetIsDeleteSuccess(true);
      }
    }
     
  }catch(error){
    console.log("something went wrong",error)
    toast.error(error)
  }finally{
    setIsLoading(false)
  }
  
}
//------------------GetDRinfo--------------
const  GetDRInfo = async () =>{
  try{
    setIsLoading(true);
    const LabID = sessionStorage.getItem('LabID')
    const result  = await GetDocotrInfo(LabID)
    SetDRData(result);
    SetIsDeleteSuccess(false);
  }catch(error){
    toast.error('Error Load data:', error);
  }finally{
    setIsLoading(false)
  }
}
useEffect(() =>{
  GetDRInfo();
},[isDeleteSuccess])
//----------End-----------------
//--------------font data set--------------
const FONTFAMILY = [
  {
    value:' sans-serif',
    label:'Helvetica'
  },
  {
    value:'sans-serif;',
    label:'Arial'
  },
  {
    value:'serif',
    label:'Times New Roman'
  },
  {
    value:'Georgia serif',
    label:'Georgia '
  },
  {
    value:'monospace',
    label:'Courier '
  },
  {
    value:'fantasy',
    label:'Luminari'
  },
  {
    value:'cursive',
    label:'cursive'
  },
  {
    value:'system-ui',
    label:'system-ui'
  },
]
const FONTSIZE = [
  {
    value:'10px',
    label:'2xs'
  },
  {
    value:'12px',
    label:'xs'
  },
  {
    value:'14px',
    label:'sm'
  }, 
  {
    value:'16px',
    label:'md'
  }, 
  {
    value:'18px',
    label:'lg'
  },
  {
    value:'20px',
    label:'xl'
  }
]
const [FONTSDATA,SETFONTSDATA] = useState({
  fontfamily:'',
  fontsize:'',
  LabID: sessionStorage.getItem('LabID')
})

const onChangeFONTS = (e) =>{
  const {name,value} = e.target;
  SETFONTSDATA({
      ...FONTSDATA,
      [name]:value
    })
}
const  saveFontsSetting = ()=>{
  setIsLoading(true)
  try{
       var res = ADDTEMPLATESETTING(FONTSDATA);
       if(res != null){
         toast.success('Fonts Updated ')
       }
  }catch (error){
    toast.error(error)
  }finally{
    setIsLoading(false)
  }
}
//------------END--------------
//----------------delete Dr Info------
const handleDelete = (id) =>{
  setDRID(id);
  if(id){
    SetisDelete(true)
   }else{
    SetisDelete(false)
  }
}
const DeleteDRInfos = () =>{
  try{
   
   setIsLoading(true)
      const LabID = sessionStorage.getItem('LabID');
       var res = DeleteDrInfo(LabID,DrID)   
       if(res){
         toast.success("Doctor Info Deleted!");
         SetIsDeleteSuccess(true);
       }   
  }
  catch(error){
      toast.error(error)
  }finally{
    SetisDelete(false);
    setIsLoading(false)
  }
}
//-----------------END
//--------handle colosed-------
const handleClose = () =>{
  SetisDelete(false)
}
//---------end------
    return (
      <>
     {isLoading && <SpinnerModal isLoading={true} />}
 <div className='container-fluid Container2'>
    <div className='common-box-shadow common-bg-white common-br-3px' style={{padding:'10px'}}>
     <form  onSubmit={handleUpload}>
      <div className="form-check">
        <input 
          value="1" 
          className="form-check-input" 
          type="radio" 
          name="flexRadioDefault" 
          id="header-logotemplate" 
          checked={TEMPValue === '1'} 
          onChange={handleChange}
        />
        <label className="common-FontSize-small common-FontFamily"  htmlFor="header-logotemplate">
          Top Header Logo Template
        </label>
      </div>
      <div className="form-check">
        <input 
          value="2" 
          className="form-check-input" 
          type="radio" 
          name="flexRadioDefault" 
          id="Premium-Template" 
          checked={TEMPValue === '2'} 
          onChange={handleChange}
        />
        <label className="common-FontSize-small common-FontFamily" htmlFor="Premium-Template">
          Premium Template
        </label>  
      </div>
      <div className="form-check">
        <input 
          value="3" 
          className="form-check-input" 
          type="radio" 
          name="flexRadioDefault" 
          id="simple-template" 
          checked={TEMPValue === '3'} 
          onChange={handleChange}
        />
        <label className="common-FontSize-small common-FontFamily" htmlFor="simple-template">
          Simple Template
        </label>
      </div>
      <div className="form-check">
        <input 
          value="4" 
          className="form-check-input" 
          type="radio" 
          name="flexRadioDefault" 
          id="T4" 
          checked={TEMPValue === '4'} 
          onChange={handleChange}
        />
        <label className="common-FontSize-small common-FontFamily" htmlFor="T4">
          New temp 4  
        </label>  
      </div>
         <hr></hr>
         <div>
          <span style={{color:`${boxBorderColor}`}} className='common-FontSize-small common-FontFamily'>Note: If the size of uploaded pics not fit in the given box it may show stretch </span>
               <div style={{border:`1px solid ${boxBorderColor}` ,width:'820px'}}>
                {previewImage ? (
                  <img 
                    src={previewImage}
                    alt='Selected'
                    style={{ maxWidth: '100%', maxHeight: '130px', height: '130px',}}
                  />
                  )
                :(
                  <img 
                  src={"data:image/png;base64," + image?.[0]?.data} 
                  alt='Selected' 
                  style={{ maxWidth: '100%', maxHeight: '130px', height: '130px', }} />
                )
                }

               </div> 
               <div className='file-button'>
                  <input required type="file" onChange={handleFileChange} />
                  <button type='submit' className=" common-button" >Update</button>
               </div> 
         </div>
           </form>    
    </div>
     <div className='d-flex'>
        <div className='col-6 common-box-shadow common-bg-white common-br-3px' style={{padding:'10px',marginTop:'10px' }}>
        <h5 className="common-FontSize-small common-FontFamily">Note: Select your desire Font Family and Size</h5>
        <hr></hr>
        <div className="row">
            <div className='col-sm-4'>
              <label className='common-Labels' htmlFor="FONTFAMILY">Font Family</label>
            </div>
            <div className='col-sm-6'>
            <select
                    className="form-select  common-Input"
                    id='FONTFAMILY'
                    name='fontfamily'
                    required
                    onChange={onChangeFONTS}
                    value={FONTSDATA.fontfamily}
                    >
                    {FONTFAMILY.map((option) => (
                    <option key={option.value} value={option.value}>
                    {option.label}
                    </option>
                    ))}
                  </select>
            </div>
           
            <div className='col-sm-4'>
                <label className='common-Labels' htmlFor="FONTSIZE">Font Zise</label>
            </div>
            <div className='col-sm-6 mt-2'>
                <select
                      className="form-select  common-Input"
                      id='FONTSIZE'
                      name='fontsize'
                      required
                      onChange={onChangeFONTS}
                      value={FONTSDATA.fontsize}
                      >
                      {FONTSIZE.map((option) => (
                      <option key={option.value} value={option.value}>
                      {option.label}
                      </option>
                      ))}
                  </select>
              </div>
              </div>
              <div style={{marginTop:'5px'}}>
                <button type='button'  className=" common-button" onClick={saveFontsSetting}>update</button>
              </div>
        </div>
      </div>  
    <div className='row justify-content-between'>
       <div className='col-sm-6'>
        <div className='common-box-shadow common-bg-white common-br-3px' style={{ padding:'10px',marginTop:'10px' }}>
          <h5 className="common-FontSize-small common-FontFamily">Note:Add Doctor Info for Premium Template .</h5>
            <hr></hr>
            <div className="row">
            <div className='col-sm-4'>
                <label className='common-Labels' htmlFor="drname">DR. Name:</label>
              </div>
              <div className="col-sm-6">  
                <input
                  className="form-control form-control common-Input"
                  id='drname'
                  name='drname'
                  onChange={onChange}
                  value={Drdata.drname}
                  required
                />
              </div>
              <div className='col-sm-4'>
                <label className='common-Labels' htmlFor="designation">Designation:</label>
              </div>
              <div className="col-sm-6 mt-2"> 
                <input
                  className="form-control form-control common-Input"
                  id='designation'
                  name='designation'
                  onChange={onChange}
                  value={Drdata.designation}
                  required
                />
              </div>
              <div className='col-sm-4'>
                <label className='common-Labels' htmlFor="description">Description:</label>
              </div>
              <div className="col-sm-6 mt-2">
                <input
                  className="form-control form-control common-Input"
                  id='description'
                  name='description'
                  onChange={onChange}
                  value={Drdata.description}       
                  required       
                />
              </div>
              <div className='col-sm-4'>
                <label className='common-Labels' htmlFor="address">Address:</label>
              </div>
              <div className="col-sm-6 mt-2">
                <input
                  className="form-control form-control common-Input"
                  id='address'
                  name='address'
                  onChange={onChange}
                  value={Drdata.address}
                />
              </div>
            </div>
            <div className="row">
              <div className='col-sm-3 mt-4'>
                <button type='button' onClick={Submit} className=" common-button" >Save</button>
              </div>
            </div>
        </div>
       </div>
       <div className='col-sm-6'>
       <div className='common-box-shadow common-bg-white common-br-3px' style={{ padding:'10px',marginTop:'10px' }}>
        <Table  stickyHeader aria-label="sticky table" >
                <TableHead>
                    <StyledTableRow>
                        <StyledTableCell className='DTC1'>Dr Name</StyledTableCell>
                        <StyledTableCell className='DTC1'>Designation</StyledTableCell>
                        <StyledTableCell className='DTC1'>Description</StyledTableCell>
                        <StyledTableCell className='DTC1'>Action  </StyledTableCell>

                    </StyledTableRow>
                </TableHead>
                <TableBody>  
                        {DRINFOData.map((data, index) => (
                        <StyledTableRow  key={index}>
                          <StyledTableCell className='DTC'>{data.drname} </StyledTableCell>
                          <StyledTableCell className='DTC'>{data.designation}</StyledTableCell>
                          <StyledTableCell className='DTC'>{data.description}</StyledTableCell>
                          <StyledTableCell className='DTC'>
                            <button type='button' className='Delete-Test-Button' onClick={() => handleDelete(data.drid)}><DeleteForeverRoundedIcon  sx={{fontSize:'18px'}} />Delete</button> 
                          </StyledTableCell> 
                        </StyledTableRow>
                        ))}
                </TableBody>
        </Table>
        </div>
       
       </div>
    </div>

    <div>
                <Dialog PaperProps={{ style: { borderRadius:'0px',padding:'10px'},}} open={isDelete} keepMounted onClose={handleClose} className='common-modal'  TransitionComponent={Transition}>
                  <div style={{textAlign:'end'}}>
                    <button className='common-cross-button' onClick={handleClose}>
                      <CloseRoundedIcon />
                    </button>
                  </div>
                        <DialogTitle style={{textAlign:'center'}} className='common-FontFamily'>
                          <CancelOutlinedIcon style={{fontSize:'100px',color:'red'}}/><br></br>
                          <h4>Are you sure!</h4>
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-slide-description">
                            <span>  Do you really want to delete these record?</span><br></br>
                            <span> This proccess cannot be undone.</span> 
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{textAlign:'center'}}>
                          <button className='btn dialog-Cancel-button' onClick={handleClose}>Cancel</button>
                          <button className='btn dialog-Delete-button' onClick={DeleteDRInfos}>Delete</button>
                        </DialogActions>
                </Dialog>
    </div> 
  </div>
 </>
       
    );
};

export default TemplateSetting;
