import React, { useState, useEffect } from 'react';
import { GetDashboard } from '../Services/Dashboard';
import { Monthly_Lab_Data,MonthlyDailyReport } from '../Services/Dashboard';
import Labimage from '../assets/labimg.png'
import Financeimage from '../assets/e.png'
import Sells from '../assets/pts.png'
import pendingTest from '../assets/pendingTest.png'
import Highcharts from 'highcharts';
import { useNavigate, useLocation } from 'react-router-dom';
import '../UI/Dashboard.css';
import { toast } from 'react-toastify';
import moment from 'moment/moment';
import SpinnerModal from '../Spinner/SpinnerModal';




const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState([]);

  //const[Date_Report,SetDaterange_Report] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const[MonthlyReport,SetMonthlyReport] = useState([]);
  const[DailyReports,DailyReport] = useState([]);
  const navigate = useNavigate();
  
 

  useEffect(() => {
    getDashboardData();
  }, []);

  const getDashboardData = async () => {
    try{
      setIsLoading(true);
      const LabID = sessionStorage.getItem('LabID');
      const response = await GetDashboard(LabID);
      setDashboardData(response);
    }catch(error){
      toast.error("Unabled to get fianance data",error)
    }finally{
      setIsLoading(false);
    }
   
  };



   //*************** States ********************* */
   const currentYear = new Date().getFullYear();
  const startDate = new Date(currentYear, 0, 1).toISOString().split('T')[0]; 
  const endDate = new Date(currentYear, 11, 31).toISOString().split('T')[0]; 

  const [formData, setFormData] = useState({
    StartDate: startDate,
    EndDate: endDate,
    LabID: sessionStorage.getItem('LabID')
  });
  const onchange = (e) =>{
    const {name,value} = e.target;
    setFormData({
        ...formData,
        [name]:value,
    }) 
}

//------------Commenting date report for curent Know ------
// const GetDashboardStates = async () =>{
//   try{
//     setIsLoading(true);
//     const Date_Report = await Monthly_Lab_Data(formData);
//       SetDaterange_Report(Date_Report)
//   }catch(error){
//     toast.error("Unabled to get States data",error)
//   }finally{
//     setIsLoading(false);
//   }
// }
// useEffect(() =>{
//   GetDashboardStates();
// },[])
// const onApply = () =>{
//   GetDashboardStates();
// }

//-------------END -------------
//     --------- Monthly daily report----------
const GetMonthlyDaily = async () =>{
  try{
    setIsLoading(true);
    const LabID = sessionStorage.getItem('LabID');
    const response = await MonthlyDailyReport(LabID);
     SetMonthlyReport(response.monthlyReports);
     DailyReport(response.dailyReports);
      
  }catch(error){
    toast.error("Unabled to get States data",error)
  }finally{
    setIsLoading(false);
  }
}

useEffect(()=>{
  GetMonthlyDaily();
},[])



//-------------- Monthly high chart----------------
useEffect(()=>{
  const months = MonthlyReport.map(report => report.monthName);
  const totalTests = MonthlyReport.map(report => report.totalTest);
  const totalGrandTotals = MonthlyReport.map(report => report.totalGrandTotal);
  const receivedAmounts = MonthlyReport.map(report => report.receivedAmount);
  const dueAmounts = MonthlyReport.map(report => report.dueAmount);
  const totalDiscounts = MonthlyReport.map(report => report.totalDiscount);
  Highcharts.chart('Highchart-Container', {
    chart: {
        type: 'column'
    },
    title: {
        text: '<a href="#" id="navigate-to-Monthly" style="float: right; color: #007bff;font-size:12px;">Monthly Statement</a>',
        useHTML: true,
        align: 'left'
    },
    xAxis: {
        categories:months,
        crosshair: true,
        title: {
          text: ''
        }
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Amount in pkr'
      }
    },
    tooltip: {
      shared: true
    },
    plotOptions: {
        column: {
            pointPadding: 0.2,
            borderWidth: 0
        }
    },
    series: [
      { name: 'Total Tests', data: totalTests },
      { name: 'Grand Total', data: totalGrandTotals },
      { name: 'Received Amount', data: receivedAmounts },
      { name: 'Due Amount', data: dueAmounts },
      { name: 'Total Discount', data: totalDiscounts }
    ]
});
const link = document.getElementById('navigate-to-Monthly');
if (link) {
  link.addEventListener('click', (e) => {
    e.preventDefault(); // Prevent the default anchor behavior
    navigateToMonth(); // Call your navigate function
  });
}
},[MonthlyReport])

const navigateToMonth = () =>{
  navigate('/Home/Reports');
}
//---------- END----------------
//---------------------High Chart daily report------
useEffect(()=>{
  const limitedReports = DailyReports.slice(0, 10);
  const categories = limitedReports.map(report => `${moment(report.recordDate).format('DD/MM/YY')}`);
  const totalTests = limitedReports.map(report => report.totalTest);
  const totalGrandTotals = limitedReports.map(report => report.totalGrandTotal);
  const receivedAmounts = limitedReports.map(report => report.receivedAmount);
  const dueAmounts = limitedReports.map(report => report.dueAmount);
  const totalDiscounts = limitedReports.map(report => report.totalDiscount);
  Highcharts.chart('Highchart-Daily-Container', {
    chart: {
        type: 'column'
    },
    title: {
        text: '<a href="#" id="navigate-to-daily" style="float: right; color: #007bff;font-size:12px;">Daily Statement</a>',
        useHTML: true,
        align: 'left'
    },
    xAxis: { 
        categories:categories,
        crosshair: true,
        title: {
          text: ''
        }
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Amount in pkr'
      }
    },
    tooltip: {
      shared: true
    },
    plotOptions: {
        column: {
            pointPadding: 0.2,
            borderWidth: 0
        }
    },
    series: [
      { name: 'Total Tests', data: totalTests },
      { name: 'Grand Total', data: totalGrandTotals },
      { name: 'Received Amount', data: receivedAmounts },
      { name: 'Due Amount', data: dueAmounts },
      { name: 'Total Discount', data: totalDiscounts }
    ]
});
const link = document.getElementById('navigate-to-daily');
if (link) {
  link.addEventListener('click', (e) => {
    e.preventDefault(); // Prevent the default anchor behavior
    navigateToDaily(); // Call your navigate function
  });
}
},[MonthlyReport])
const navigateToDaily = () =>{
  navigate('/Home/Reports');
}
//---------- END----------------
//------- Commenting Date range report as  no need to get it for current ma implement later --------
//-------------HighCHart - Date range report----------
// useEffect(()=>{
//   const months = Date_Report.map(report => report.monthName);
//   const totalTests = Date_Report.map(report => report.totalTest);
//   const totalGrandTotals = Date_Report.map(report => report.totalGrandTotal);
//   const receivedAmounts = Date_Report.map(report => report.receivedAmount);
//   const dueAmounts = Date_Report.map(report => report.dueAmount);
//   const totalDiscounts = Date_Report.map(report => report.totalDiscount);
//   Highcharts.chart('Highchart-Daterange-Container', {
//     chart: {
//         type: 'column'
//     },
//     title: {
//         text: '<a href="#" id="navigate-to-Date" style="float: right; color: #007bff;font-size:12px;">Custome Date Statement</a>',
//         useHTML: true,
//         align: 'left'
//     },
//     xAxis: {
//         categories:months,
//         crosshair: true,
//         title: {
//           text: 'Month'
//         }
//     },
//     yAxis: {
//       min: 0,
//       title: {
//         text: 'Amount in pkr'
//       }
//     },
//     tooltip: {
//       shared: true
//     },
//     plotOptions: {
//         column: {
//             pointPadding: 0.2,
//             borderWidth: 0
//         }
//     },
//     series: [
//       { name: 'Total Tests', data: totalTests },
//       { name: 'Total Grand Total', data: totalGrandTotals },
//       { name: 'Received Amount', data: receivedAmounts },
//       { name: 'Due Amount', data: dueAmounts },
//       { name: 'Total Discount', data: totalDiscounts }
//     ]
// });
// const link = document.getElementById('navigate-to-Date');
// if (link) {
//   link.addEventListener('click', (e) => {
//     e.preventDefault(); // Prevent the default anchor behavior
//     navigateToDateRange(); // Call your navigate function
//   });
// }
// },[Date_Report])

// const navigateToDateRange = () =>{
//   navigate('/Home/DateRange-Reports');
// }
// //-----------------END-------------------

useEffect(() => {
  const months = MonthlyReport.map(report => report.monthName);
  const totalTests = MonthlyReport.map(report => report.totalTest);
  const totalGrandTotals = MonthlyReport.map(report => report.totalGrandTotal);
  const receivedAmounts = MonthlyReport.map(report => report.receivedAmount);
  const dueAmounts = MonthlyReport.map(report => report.dueAmount);
  const totalDiscounts = MonthlyReport.map(report => report.totalDiscount);
  Highcharts.chart('Highchart-Progress-container', {
    chart: {
      type: 'line'  // Change this to whatever chart type you need
    },
    title: {
      text: 'Monthly Progress Report', // You can adjust the title as per your need
      align: 'left'
    },
    // subtitle: {
    //   text: 'Data from Daily Reports. Source: Custom Data', 
    //   align: 'left'
    // },
    xAxis: {
      categories: months,
      accessibility: {
        rangeDescription: 'Range: Last 10 days'
      },
      title: {
        text: 'Date'
      }
    },
    yAxis: {
      title: {
        text: 'Amount in PKR'
      }
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle'
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false
        },
        pointStart: 0
      }
    },
    series: [
      {
        name: 'Total Tests',
        data: totalTests
      },
      {
        name: 'Grand Total',
        data: totalGrandTotals
      },
      {
        name: 'Received Amount',
        data: receivedAmounts
      },
      {
        name: 'Due Amount',
        data: dueAmounts
      },
      {
        name: 'Total Discount',
        data: totalDiscounts
      }
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom'
            }
          }
        }
      ]
    }
  });

  // If needed, attach click functionality to navigate link
  const link = document.getElementById('navigate-to-daily');
  if (link) {
    link.addEventListener('click', (e) => {
      e.preventDefault(); // Prevent the default anchor behavior
      navigateToDaily(); // Call your navigate function
    });
  }

}, [DailyReports]);  // Ensure the useEffect depends on the DailyReports data


const navigateToPendingTest = () =>{
  navigate('/Home/PendingTest');
}
  return (
    <>
    {isLoading && <SpinnerModal isLoading={true} />}
       <div className='container-fluid Container2' >
         <div className='row d-flex'> 
            <div className='col-sm-3'>
               <div className='dashboard-boxes common-box-shadow  common-br-3px'>
                  <div className='d-flex justify-content-between'>
                      <div>
                          <h6 className='common-FontFamily'>Total Patient</h6>
                          <h6 className='common-FontFamily'>{dashboardData.totalPatients}</h6>
                      </div>
                      <div>
                        <img style={{width:'50px',}} src={Sells} />
                      </div>
                  </div>
               </div>
            </div>
            <div className='col-sm-3 ' >
              <div className='dashboard-boxes common-box-shadow common-bg-white common-br-3px'>
                    <div className='d-flex justify-content-between'>
                        <div>
                        <h6 className='common-FontFamily'>Total Test </h6>
                        <h6 className='common-FontFamily'> {dashboardData.totalTests}</h6>
                        </div>
                        <div>
                          <img style={{width:'50px',}} src={Labimage} />
                        </div>
                      </div>
              </div>
           </div>
           <div className='col-sm-3'>
              <div className='dashboard-boxes common-box-shadow common-bg-white common-br-3px'>
                  <div className='d-flex justify-content-between'>
                      <div>
                      <h6 className='common-FontFamily'>Total Sell </h6>
                      <h6 className='common-FontFamily'><span>RS.</span>   {dashboardData.totalSell}</h6>
                      </div>
                      <div>
                        <img style={{width:'50px',}} src={Financeimage}  />
                      </div>
                    </div>
              </div>
           </div>
           <div className='col-sm-3'>
              <div className='dashboard-boxes common-box-shadow common-bg-white common-br-3px'>
                  <div className='d-flex justify-content-between'>
                      <div>
                      <h6 className='common-FontFamily'>Pending Test </h6>
                      <h6 className='common-FontFamily'>{dashboardData.pendingTestCount}</h6>
                      </div>
                      <div>
                        <img style={{width:'50px',}} src={pendingTest}  />
                      </div>
                    </div>
              </div>
           </div>
         </div>
         <div className='row justify-content-between' style={{gap:'', padding:'10px 0px'}}>
               <div className='col-sm-6'>
                 <div>
                     <div id='Highchart-Container' className='common-box-shadow common-bg-white common-br-3px'></div>
                 </div>
               </div>
                <div className='col-sm-6'>
                    <div>
                      <div id='Highchart-Daily-Container' className=' common-box-shadow common-bg-white common-br-3px'></div>
                    </div>
                </div>
              {/*------- Commenting Date range report as  no need to get it for current ma implement later --------
                <div className='col-sm-5'style={{alignItems:'center',border:'1px solid lightgray',borderRadius:'5px'}}>
                 <div className='row'>
                    <div className='col-sm mt-2'>
                          <label className='common-Labels' >Date from</label>
                            <input
                                  className="form-control common-Input"
                                  id='DateFrom'
                                  name='StartDate'
                                  required
                                  type='date'
                                  onChange={onchange}
                                  value={formData.StartDate}
                            />
                      </div>
                      <div className='col-sm mt-2'>
                      <label className='common-Labels' >Date To</label>
                        <input
                              className="form-control common-Input"
                              id='DateTo'
                              name='EndDate'
                              required
                              type='date'
                              onChange={onchange}
                              value={formData.EndDate}
                        />
                      </div>
                    <div className='col-sm-3 mt-3'>
                      <button type='button' style={{marginTop:'14px'}} onClick={onApply}  className="common-Apply" >Apply</button>
                    </div>
              
                 </div>
                   <hr></hr>
                   <div id='Highchart-Daterange-Container'></div>
              </div>  */}
             
         </div>

          <div className='row justify-content-between' style={{ padding:'10px 0px'}}> 
                <div className='col-sm-6'>
                  <div>
                      <div id='Highchart-Progress-container' className=' common-box-shadow common-bg-white common-br-3px'></div>
                  </div>
                </div>
           </div>
       </div>
    </>
  );
}

export default Dashboard;
