// AuthGuard.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from './Layout';
import Footer from './Footer';
import TokenEXPhandler from './TokenEXPhandler';

const AuthGuard = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    if (!token) {
      navigate('/');
    }
  }, [navigate]);
  return <> 
   <div className='row common-background-color'>
       <div style={{width:'5%'}}>
        <Layout />
       </div>
      <main style={{marginLeft:'50px',width:'95%'}}>
        {children}
      </main>
  </div>
      {/* <TokenEXPhandler /> */}
         
  </>;
};

export default AuthGuard;
