import React from 'react'
import  {useEffect, useState,useCallback} from 'react'
import { RetrivePatientsData,DeletePatient,PatientRegistration_LabOrders,GloabalSearchPatient } from '../Services/PatientServicces';

import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Pagination,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Box,
  } from '@mui/material';
import PreviewRoundedIcon from '@mui/icons-material/PreviewRounded';
import Slide from '@mui/material/Slide';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useNavigate } from 'react-router-dom';
import SpinnerModal from '../Spinner/SpinnerModal';
import { useApi } from '../ContextApi/ContextApi';
import { styled } from '@mui/material/styles';
import  { tableCellClasses } from '@mui/material/TableCell';
import LibraryAddRoundedIcon from '@mui/icons-material/LibraryAddRounded';
import ArrowOutwardRoundedIcon from '@mui/icons-material/ArrowOutwardRounded';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import Stack from '@mui/material/Stack';
import '../UI/PatientList.css'
import LabOrder from './LabOrder';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#2c6bd7',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      backgroundColor: '#ffffff',
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const Transition = React.forwardRef(function Transition(props, ref) {
    const transitionDuration = 500; // milliseconds
    return <Slide direction="down" ref={ref} {...props} timeout={transitionDuration} />;
  });
const PatientList = () =>{
    const { isSubmitted ,setSubmissionStatus} = useApi();
    const [PatientData,setPatientData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const[isPateintID,SetPatientID] = useState([]);
    const [isDelete,SetisDelete] = useState(false);
    const [lgShow, setLgShow] = useState(false);
    const [LabOrderData, setlabOrderData] = useState({});
    const [IsLaBOrder,setisLabOrder] = useState(false);
    const [inputdata, setInputData] = useState('');
    const [ModalHeadrData,SetModalHeaderData] = useState({
      patientName:'',
      age:'',
      Sex:''
    });
    const[formData,setformData] = useState({
      PatientID:'',
      PatientName:'',
      ReferredBy:'',
      ContactNumber:'',
      gender:'male',
      Age:'',
      Comments :'',
      DOB:'',
      Address:'',
      LaboratoryID: sessionStorage.getItem('LabID')
  });
    const navigate = useNavigate();



    const GettignData = async ()=>{
     try{
        setIsLoading(true)
         const LabID = sessionStorage.getItem('LabID')
         const Response = await RetrivePatientsData(LabID);
         setPatientData(Response)
         setSubmissionStatus(false)
        }
        catch(eror){
            console.log(eror)
        }finally{
            setIsLoading(false)
        }
     }

    useEffect(() => {
        GettignData();
    },[isSubmitted])
     

//-------------------Pagination

const itemsPerPage = 10; // Number of items per page
const [currentPage, setCurrentPage] = useState(1); // Pages start from 1

// Calculate the range of items to display on the current page
const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const limitedData = PatientData.slice(indexOfFirstItem, indexOfLastItem);

// Handle page change
const handlePageChange = (event, newPage) => {
  setCurrentPage(newPage);
};
//----------------end-----------
////--------------open profile------------

const NavigatetoProfile = (id) =>{
    navigate(`/Home/PatientProfile/${id}`)
}
//------------end---------------
//-----------Delete Pateint--------
const handleDelete = (id) =>{
  SetPatientID(id);
  if(id){
    SetisDelete(true)
   }else{
    SetisDelete(false)
  }
}


 const DeletePatients = () =>{
   try{
    setIsLoading(true)
       const LabID = sessionStorage.getItem('LabID');
        var res = DeletePatient(LabID,isPateintID)   
        if(res){
          toast.success("Patient Deleted!")
          setSubmissionStatus(true)
        }   
   }
   catch(error){
       toast.error(error)
   }finally{
     SetisDelete(false);
     setIsLoading(false)
   }
 }
//--------------------handle closed----------
const handleClose = () =>{
  SetisDelete(false)
}
//------------End---------
//================Search patient =============
const handleChange = (e) => {
  const value = e.target.value.trim();  
  if(value == null || value == ""){
    GettignData();
  }else{
    const filtered = PatientData.filter((patient) => {
      const patientName = patient?.patientName?.toLowerCase()?.trim();
      const phoneNumber = patient?.contactNumber?.toLowerCase()?.trim();
      const searchValue = value.toLowerCase();
      return  patientName?.includes(searchValue) || phoneNumber?.includes(searchValue); 
    });
    setPatientData(filtered);
  }
  }


//===================END==================
//-------------------Handle placed order------
 
 const onLab = (patientID,PatientName,age,sex) =>{
    setLgShow(true)
    setformData((prevData) => ({
      ...prevData,
      PatientID: patientID
    }));
    SetModalHeaderData( (prevData) =>({
      ...prevData,
      PatientName: PatientName,
      age:age,
      Sex:sex,
    }))

 }


//-----------------use call back ----------
const handleOrderFormSubmit = useCallback((orderFormData) => {
  if (orderFormData) {
    setisLabOrder( true);
    setlabOrderData(orderFormData);
    
  }else{
    toast.warning("no data has been selected")
  }
}, []);
const Submit = async ()  =>{
  setIsLoading(true);
  try {
    if(LabOrderData.length > 0){
      const response = await PatientRegistration_LabOrders(formData, LabOrderData);
      if (response) {
        toast.success('Action successfully');
        setLgShow(false)
      }
    }else{
      toast.warning("Please add test")
    }
  } catch (error) {
    toast.error('Something went wrong while adding patient');
  } finally {
    setIsLoading(false);
  }
}
//-------end--------

return (
    <>
{isLoading && <SpinnerModal isLoading={true} />}
    <div className='container-fluid Container2'>
    <div className='Common-min-height pl-child-div common-box-shadow common-bg-white common-br-3px'>
            <TableContainer className='PatientListTable'>
                <Table  stickyHeader aria-label="sticky table" >
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell className='PTC1'>Patient Name</StyledTableCell>
                            <StyledTableCell className='PTC1'> Referred by</StyledTableCell>
                            <StyledTableCell className='PTC1'> Gender</StyledTableCell>
                            <StyledTableCell className='PTC1'> Phone</StyledTableCell>
                            <StyledTableCell className='PTC1'> Age</StyledTableCell>
                            <StyledTableCell className='PTC1'>Comments</StyledTableCell>
                            <StyledTableCell className='PTC1'>DOB</StyledTableCell>
                            <StyledTableCell className='PTC1'>Address</StyledTableCell>
                            <StyledTableCell className='PTC1'>
                             <input className="form-control common-Input" type="search" placeholder="Search" onChange={handleChange} aria-label="Search"></input>
                            </StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    {limitedData.length > 0 ?
                    <TableBody>  
                           {limitedData.map((data, index) => (
                            <StyledTableRow  key={index}>
                             <StyledTableCell onClick={() => NavigatetoProfile(data.patientID)}  className='PTC common-FontWeight common-cursor common-Link'>{data.patientName.toUpperCase()}</StyledTableCell>
                             <StyledTableCell className='PTC'>{data.referredBy}</StyledTableCell>
                             <StyledTableCell className='PTC'>{data.sex}</StyledTableCell>
                             <StyledTableCell className='PTC'>{data.contactNumber}</StyledTableCell>
                             <StyledTableCell className='PTC'>{data.age}</StyledTableCell>
                             <StyledTableCell className='PTC'>{data.comments}</StyledTableCell>
                             <StyledTableCell className='PTC'>{data.DOB}</StyledTableCell>
                             <StyledTableCell className='PTC'>{data.address}</StyledTableCell> 
                             <StyledTableCell className='Patient-List-Action1'>
                                <button onClick={() => NavigatetoProfile(data.patientID) } className='View_Button'><ArrowOutwardRoundedIcon sx={{fontSize:'13px'}}/> view</button>
                                <button type='button'  className='Delete-Patient-Button' onClick={() => handleDelete(data.patientID)}><DeleteForeverRoundedIcon sx={{fontSize:'18px'}} />Delete</button>
                                <button type='button'  className='Add-Lab-Button' onClick={() => onLab(data.patientID,data.patientName,data.age,data.sex)}><LibraryAddRoundedIcon sx={{fontSize:'20px'}} /> Add Lab</button>

                             </StyledTableCell> 
                            </StyledTableRow>
                            ))}
                    </TableBody>
                    :
                    <TableBody className='row' style={{textAlign:'center'}}>
                       <p style={{color:'gray'}}> No Record Found</p>
                    </TableBody>}
                </Table>
                <hr></hr>
                <Stack>
                  <Pagination
                      variant="outlined"
                      shape="rounded"
                      count={Math.ceil(PatientData.length / itemsPerPage)} // Total pages
                      page={currentPage}
                      onChange={handlePageChange} // Correct prop for handling page changes
                    />
                  </Stack>
                
            </TableContainer>
            </div>
            <div>
                <Dialog PaperProps={{ style: { borderRadius:'0px',padding:'10px'},}} open={isDelete} keepMounted onClose={handleClose} className='common-modal'  TransitionComponent={Transition}>
                  <div style={{textAlign:'end'}}>
                    <button className='common-cross-button' onClick={handleClose}>
                      <CloseRoundedIcon />
                    </button>
                  </div>
                        <DialogTitle style={{textAlign:'center'}} className='common-FontFamily'>
                          <CancelOutlinedIcon style={{fontSize:'100px',color:'red'}}/><br></br>
                          <h4>Are you sure!</h4>
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-slide-description">
                            <span>  Do you really want to delete these record?</span><br></br>
                            <span> This proccess cannot be undone.</span> 
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{textAlign:'center'}}>
                          <button className='btn dialog-Cancel-button' onClick={handleClose}>Cancel</button>
                          <button className='btn dialog-Delete-button' onClick={DeletePatients}>Delete</button>
                        </DialogActions>
                </Dialog>
            </div>  

          <div>
            <Modal size="xl" show={lgShow}  dialogClassName="modal-90w" aria-labelledby="example-custom-modal-styling-title">
              <Modal.Header className='d-flex align-center justify-content-between'>
                <Modal.Title className='d-flex align-center'>
                  <h4 className='common-FontFamily'>{ModalHeadrData?.PatientName?.toUpperCase()}</h4>
                  <p className='common-FontFamily common-FontSize-small'>{ModalHeadrData.age} Year | {ModalHeadrData.Sex}</p>
                </Modal.Title>
                   <div>
                     <button className="Model-Cross" onClick={() => setLgShow(false)}> <CloseRoundedIcon  /></button>
                  </div>
              </Modal.Header>
              <Modal.Body>
                <LabOrder onSubmit={handleOrderFormSubmit}/>
                <button className="common-button" type='button' onClick={Submit}>save</button>
              </Modal.Body>
            </Modal>
         </div>
        </div>
        </>
        
    )
}
export default PatientList;